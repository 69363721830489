<template>
  <b-card v-if="user">
    <b-row align-h="between" class="mx-0">
      <div class="d-flex">
        <feather-icon icon="FileTextIcon" size="19" />
        <h4 class="mb-0 ml-50">
          {{ $t('admin.labs.form.generalInformation') }}
        </h4>
      </div>
      <b-badge>
        {{ getRoleTitle }}
      </b-badge>
    </b-row>
    <validation-observer ref="refFormObserver">
      <b-form class="mt-1">
        <b-row>
          <b-col cols="12" lg="6" md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('profile.editUserForm.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('profile.editUserForm.name') + '*'"
                label-for="userName"
              >
                <b-form-input
                  v-model="user.name"
                  id="userName"
                  :placeholder="$t('profile.editUserForm.name')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('profile.editUserForm.lastName')"
              rules="required"
            >
              <b-form-group
                :label="$t('profile.editUserForm.lastName') + '*'"
                label-for="userLastName"
              >
                <b-form-input
                  v-model="user.lastName"
                  id="userLastName"
                  :placeholder="$t('profile.editUserForm.lastName')"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <b-form-group
              :label="$t('profile.editUserForm.email')"
              label-for="userEmail"
            >
              <b-form-input
                v-model="user.email"
                id="userEmail"
                :placeholder="$t('profile.editUserForm.email')"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <validation-provider
              #default="validationContext"
              :name="$t('profile.editUserForm.phone')"
            >
              <b-form-group
                :label="$t('profile.editUserForm.phone')"
                label-for="userPhone"
              >
                <b-form-input
                  v-model="user.phoneNumber"
                  id="userPhone"
                  :placeholder="$t('profile.editUserForm.phone')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Changing roles -->
          <!-- <b-col cols="12" lg="6" md="6">
            <validation-provider #default="validationContext" name="Roles">
              <b-form-group label="Papel" label-for="roles">
                <v-select
                  v-model="user.role"
                  label="label"
                  placeholder="Papel"
                  :options="roles"
                  :clearable="false"
                  :reduce="(option) => option.value"
                />
              </b-form-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col> -->
        </b-row>

        <!-- Header: Address -->
        <div v-if="canShowAddress">
          <div class="d-flex mt-2">
            <feather-icon icon="MapPinIcon" size="19" />
            <h4 class="mb-0 ml-50">
              {{ $t('profile.editUserForm.address') }}
            </h4>
          </div>

          <b-row class="mt-1">
            <b-col cols="12" md="6" lg="4">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.editUserForm.addressLine1')"
              >
                <b-form-group
                  :label="$t('profile.editUserForm.addressLine1') + '*'"
                  label-for="Address Line 1"
                >
                  <b-form-input
                    id="addressline1"
                    v-model="user.address.line1"
                    :placeholder="$t('profile.editUserForm.addressLine1')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.editUserForm.number')"
              >
                <b-form-group
                  :label="$t('profile.editUserForm.number')"
                  label-for="number"
                >
                  <b-form-input
                    id="number"
                    v-model="user.address.number"
                    type="number"
                    :placeholder="$t('profile.editUserForm.number')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.editUserForm.addressLine2')"
              >
                <b-form-group
                  :label="$t('profile.editUserForm.addressLine2')"
                  label-for="Address Line 2"
                >
                  <b-form-input
                    id="addressline2"
                    v-model="user.address.line2"
                    :placeholder="$t('profile.editUserForm.addressLine2')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.editUserForm.zip')"
              >
                <b-form-group
                  :label="$t('profile.editUserForm.zip')"
                  label-for="zipCode"
                >
                  <b-form-input
                    id="zip code"
                    v-model="user.address.zipCode"
                    :placeholder="$t('profile.editUserForm.zip')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" lg="4">
              <validation-provider
                #default="validationContext"
                :name="$t('profile.editUserForm.country')"
              >
                <b-form-group
                  :label="$t('profile.editUserForm.country')"
                  label-for="country"
                >
                  <country-select
                    class="country-form"
                    v-model="user.address.country"
                    :country="user.address.country"
                    topCountry="BR"
                    :usei18n="false"
                    :placeholder="$t('profile.editUserForm.country')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <b-row class="mt-2 pr-1" align-h="end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="cancel()"
          >
            {{ $t('profile.editUserForm.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit()"
          >
            <b-spinner v-if="loading" small></b-spinner>

            <span v-else>{{ $t('profile.editUserForm.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInvalidFeedback,
  BBadge,
} from 'bootstrap-vue'
import userStoreModule from '@/views/admin/users/userStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import RoleEnum from '@/utils/roles'
import store from '@/store'
import { onUnmounted } from 'vue-demi'
import UserEntity from '@/entities/user.entity'
// import vSelect from 'vue-select'
import vueCountryRegionSelect from 'vue-country-region-select'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    BFormInvalidFeedback,
    // vSelect,
    BBadge,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      user: null,
    }
  },
  computed: {
    // roles() {
    //   return RoleEnum.listRoles()
    // },
    getRoleTitle() {
      return RoleEnum.getRoleTitle(this.user.role)
    },
    loading() {
      return this.$store.state.user.loading
    },
    canShowAddress() {
      const role = this.user.role
      if (role != RoleEnum.SUPER_ADMIN && role != RoleEnum.SELLER) return true
      else return false
    },
  },
  methods: {
    async submit() {
      const id = this.user.id
      const data = new UserEntity(this.user)
      data.updatedAt = new Date().toISOString()
      await this.$store.dispatch('user/updateUserInfo', { id, data })
      this.$router.push({ name: 'users' })
    },
    cancel() {
      this.$router.push({ name: 'users' })
    },
  },
  async created() {
    this.user = await this.$store.dispatch(
      'user/fetchOneUser',
      this.$route.params.id,
    )
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    onUnmounted(() => {
      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    )

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>
.country-form {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;

  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s;
}
</style>